import React from "react";

import { useConfig } from "gatsby-theme-advanced";

import UserLinks from "../UserLinks";
import { Caption, H3 } from "../../theme";
import { AnimatedLink } from "../Links";

import * as S from "./styles";

const Footer = (): JSX.Element => {
  const config = useConfig();

  return (
    <S.Wrapper>
      <S.LinkGrid>
        <H3>Download SoloApp for Mac & Windows</H3>
        <UserLinks includeRss />
      </S.LinkGrid>
      <S.Info>
        <Caption>
          Mail:{" "}
          <AnimatedLink to="mailto:hello@playsolo.app">
            hello@playsolo.app
          </AnimatedLink>
        </Caption>
        <Caption>
          <AnimatedLink to="https://help.playsolo.app">
            Help Center
          </AnimatedLink>
        </Caption>
        <Caption>{config.website.copyright}</Caption>
      </S.Info>
      <div></div>
    </S.Wrapper>
  );
};

export default Footer;
